import React, { useEffect, useState } from 'react'
// import Logo from "../assets/images/logo.svg"
const navs = [
    {
        name: 'Home',
        link: 'https://app.tiwiflix.pro/',
        requiresAuth: false,
        id: 1

    },
    {
        name: 'Movies',
        link: 'https://app.tiwiflix.pro/movies',
        requiresAuth: false,
        id: 2

    },
    // {
    //     name : 'Popular',
    //     link: '/popular',
    //     requiresAuth: false,
    //     id: 3

    // },
    // {
    //     name : 'New Release',
    //     link: '/new-release',
    //     requiresAuth: false,
    //     id: 4

    // },
    // {
    //     name : 'Creator Mode',
    //     link: '/creator',
    //     requiresAuth: false,
    //     id: 5

    // }
]



function Header() {


    return (

        <div className="fixed left-0 w-full  bg-hite lg:px-10 bg-main-ble z-50" style={{ backgroundColor: '#02061B' }}>
            <div className='flex justify-between items-center py-2 md:py-4  max-w-[1500px] w-full  px-4 md:px-6 mx-auto' >
                <div className="flex gap-10 ">
                    <a
                        href="/">
                        <svg width="119" height="48" viewBox="0 0 119 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.8586 20.6109L26.917 18.8732L17.4719 13.489L15.2102 12.1977C14.9796 12.0668 14.7187 11.9986 14.4536 12C14.1884 12.0014 13.9283 12.0724 13.6991 12.2058C13.4699 12.3392 13.2798 12.5303 13.1476 12.7602C13.0154 12.99 12.9458 13.2506 12.9458 13.5157V31.9328C12.9459 32.202 13.0178 32.4663 13.154 32.6984C13.2903 32.9306 13.4861 33.1222 13.7211 33.2535C13.9461 33.3808 14.2004 33.4472 14.4589 33.4459C14.7418 33.449 15.0198 33.3721 15.261 33.2241L22.6663 28.6793L20.4019 27.2918C20.2052 27.1697 20.0254 27.0223 19.8672 26.8533L15.9935 29.2273V16.1143L23.8586 20.6109Z" fill="#FFBC00" />
                            <path d="M38.1905 14.748C37.9598 14.6145 37.6979 14.5444 37.4313 14.5449C37.1681 14.5452 36.9093 14.6133 36.6801 14.7427L34.413 16.034L28.176 19.5842L25.1176 21.3219L21.0808 23.6184C20.8595 23.7443 20.6735 23.924 20.5402 24.1409C20.4068 24.3578 20.3303 24.6048 20.3178 24.8591C20.3052 25.1134 20.3571 25.3668 20.4685 25.5957C20.5799 25.8246 20.7473 26.0218 20.9551 26.1688L21.0407 26.225L23.8504 27.944L36.6293 35.7771C36.8589 35.9176 37.1219 35.9945 37.3911 35.9997C37.6602 36.005 37.926 35.9384 38.1609 35.8069C38.3959 35.6754 38.5916 35.4837 38.7278 35.2515C38.8641 35.0193 38.9361 34.755 38.9364 34.4858V16.066C38.9386 15.8003 38.8708 15.5385 38.7399 15.3072C38.609 15.0759 38.4195 14.883 38.1905 14.748ZM35.9155 31.7749L26.7484 26.1608L24.8102 24.9738L31.1568 21.362L35.9155 18.6539V31.7749Z" fill="#FFBC00" />
                            <path d="M46.5845 30V20.912H43.0005V18.8H52.7605V20.912H49.1765V30H46.5845ZM53.9791 30V21.392H56.4751V30H53.9791ZM55.2271 20.192C54.7685 20.192 54.3951 20.0587 54.1071 19.792C53.8191 19.5253 53.6751 19.1947 53.6751 18.8C53.6751 18.4053 53.8191 18.0747 54.1071 17.808C54.3951 17.5413 54.7685 17.408 55.2271 17.408C55.6858 17.408 56.0591 17.536 56.3471 17.792C56.6351 18.0373 56.7791 18.3573 56.7791 18.752C56.7791 19.168 56.6351 19.5147 56.3471 19.792C56.0698 20.0587 55.6965 20.192 55.2271 20.192ZM60.7596 30L57.6556 21.392H60.0076L62.5836 28.8H61.4636L64.1516 21.392H66.2636L68.8716 28.8H67.7516L70.4076 21.392H72.6156L69.4956 30H67.0796L64.7916 23.648H65.5276L63.1596 30H60.7596ZM73.776 30V21.392H76.272V30H73.776ZM75.024 20.192C74.5654 20.192 74.192 20.0587 73.904 19.792C73.616 19.5253 73.472 19.1947 73.472 18.8C73.472 18.4053 73.616 18.0747 73.904 17.808C74.192 17.5413 74.5654 17.408 75.024 17.408C75.4827 17.408 75.856 17.536 76.144 17.792C76.432 18.0373 76.576 18.3573 76.576 18.752C76.576 19.168 76.432 19.5147 76.144 19.792C75.8667 20.0587 75.4934 20.192 75.024 20.192ZM81.1645 23.84H86.5405V25.92H81.1645V23.84ZM81.3565 30H78.7645V18.8H87.2285V20.88H81.3565V30ZM88.8073 30V18.128H91.3033V30H88.8073ZM93.6198 30V21.392H96.1158V30H93.6198ZM94.8678 20.192C94.4091 20.192 94.0358 20.0587 93.7478 19.792C93.4598 19.5253 93.3158 19.1947 93.3158 18.8C93.3158 18.4053 93.4598 18.0747 93.7478 17.808C94.0358 17.5413 94.4091 17.408 94.8678 17.408C95.3264 17.408 95.6998 17.536 95.9878 17.792C96.2758 18.0373 96.4198 18.3573 96.4198 18.752C96.4198 19.168 96.2758 19.5147 95.9878 19.792C95.7104 20.0587 95.3371 20.192 94.8678 20.192ZM97.3443 30L101.2 24.944L101.152 26.32L97.4563 21.392H100.256L102.592 24.608L101.536 24.64L103.952 21.392H106.608L102.896 26.272V24.928L106.736 30H103.904L101.456 26.576L102.496 26.72L100.064 30H97.3443Z" fill="#F3F3F3" />
                        </svg>

                    </a>
                    <div className='hidden lg:flex items-center gap-4'>


                        {navs.map((nav, i) =>
                            <a href={nav.link} target='_blank' key={nav.id} className='text-sm text-white' rel="noreferrer"> {nav.name}</a>)}

                    </div>
                </div>
                <div className='flex gap-5 items-center'>
                    {/* <Search isAdmin={false} /> */}


                    {/* <AppConnectButton /> */}



                    {/* {<UserDropdown />} */}



                    {/* <AppSidebar /> */}

                </div>


            </div>
        </div>

    );
}

export default Header;
