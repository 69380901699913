import { CheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import { Constants } from "@videosdk.live/react-sdk";
import React, { useEffect, useState } from "react";
import { AES, enc } from 'crypto-js';
import { appCreateLiveStream } from "../api";

export function MeetingDetailsScreen({
  onClickJoin,
  _handleOnCreateMeeting,
  participantName,
  setParticipantName,
  videoTrack,
  setVideoTrack,
  onClickStartMeeting,
  setMeetingMode,
  meetingMode,
}) {
  const [studioCode, setStudioCode] = useState("");
  const [studioCodeError, setStudioCodeError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [iscreateMeetingClicked, setIscreateMeetingClicked] = useState(false);
  const [isJoinMeetingClicked, setIsJoinMeetingClicked] = useState(false);
  const [isCreator, setIsreator] = useState(false);
  const [isViewer, setIsViewer] = useState(false);
  const [streamType, setStreamType] = useState('');
  const [selectedToken, setSelectedToken] = useState('TWC');
  const [amount, setAmount] = useState(0);
  const [isReJoining, setIsReJoining] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [user, setUser] = useState({
    token: '',
    userData: {}
  })

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const searchQuery = params.get('liveQueryParams');
  const streamCode = params.get('code');
  const decodedQuery = decodeURIComponent(searchQuery)
  const streamCodeQuery = decodeURIComponent(streamCode)

  const handleMeetingCreated = async (studioCode) => {
    if (studioCode) {
      await appCreateLiveStream({ token: user.token, liveUrl: `https://live.tiwiflix.pro/?code=${studioCode}` })
      localStorage.setItem('isCreator', true)
      localStorage.setItem('streamTime', JSON.stringify(new Date()))
    }
  }

  useEffect(() => {


    if (decodedQuery) {
      try {
        const bytes = AES.decrypt(decodedQuery, 'liveQueryParams');
        const decryptedData = JSON.parse(bytes.toString(enc.Utf8));

        if (decryptedData && decryptedData?.userData?.role === 'CREATOR') {
          setIsreator(true)
          setUser({ ...decryptedData })
          localStorage.setItem('userToken', decryptedData?.token)
          if (decryptedData.userData?.creator.username) {
            setParticipantName(decryptedData?.userData?.creator?.username)
          }
          else {
            setParticipantName('')
          }
        }

      } catch (err) {
        console.log('UNABLE TO DECIPHER', err);
      }
    }

    if (streamCodeQuery) {
      setIsViewer(true)
      setStudioCode(streamCodeQuery)
    }

    const isRejoining = localStorage.getItem('isRejoining')

    if (isRejoining) {
      setIsReJoining(true)
    }

  }, [])

  useEffect(() => {

    if (streamType === 'Free') {
      setIsDisabled(false)
      return
    }
    if (streamType === 'Paid') {

      if (selectedToken && Number(amount)) {
        setIsDisabled(false)
      }
      else {
        setIsDisabled(true)

      }

      return
    }

  }, [streamType, selectedToken, amount])

  return (
    <div
      className={`flex flex-1 flex-col justify-center w-full md:p-[6px] sm:p-1 p-1.5`}
    >
      {isCreator ? <>
        <div className="flex items-center gap-3 w-full mb-8 mt-20 md:mt-0">

          <button
            onClick={() => setStreamType('Free')}
            className={`w-1/2 border p-3 rounded-lg border-1 border-[#02061B] text-white text-sm hover:bg-[#02061B] ${streamType === 'Free' ? 'bg-[#02061B]' : ''}`}>
            Free Stream
          </button>
          <button
            onClick={() => setStreamType('Paid')}
            className={`w-1/2 border p-3 rounded-lg border-1 border-[#02061B] text-white text-sm hover:bg-[#02061B] ${streamType === 'Paid' ? 'bg-[#02061B]' : ''}`}>
            Paid Stream
          </button>

        </div>
        {streamType === 'Paid' ? <div className="flex items-center gap-3 w-full mb-8 mt-0 md:mt-0">

          <div className="w-1/2">
            <p className="text-sm text-white mb-2">Select Token</p>
            <select
              name="token"
              id="token"
              onChange={(e) => setSelectedToken(e.target.value)}
              className="w-full h-10 bg-transparent rounded-lg p-2 border-none cursor-pointer text-sm outline-none">

              <option selected value={"TWC"}>TWC</option>
              <option value={"DTWC"}>DTWC</option>
            </select>
          </div>
          <div className="w-1/2">
            <p className="text-sm text-white mb-2">Imput amount</p>

            <input type="number" placeholder={`${selectedToken} amount`} onChange={(e) => setAmount(e.target.value)} className="w-full h-10 bg-transparent rounded-lg p-2 border-none cursor-pointer text-sm outline-none" />

          </div>


        </div> : <></>}</> : <></>}


      {iscreateMeetingClicked ? (
        <div className="border border-solid border-[#02061B] rounded-xl px-4 py-3  flex items-center justify-center">
          <p className="text-white text-base">{`https://live.tiwiflix.pro/?code=${studioCode}`}</p>
          <button
            className="ml-2"
            onClick={() => {
              navigator.clipboard.writeText(`https://live.tiwiflix.pro/?code=${studioCode}`);
              setIsCopied(true);
              setTimeout(() => {
                setIsCopied(false);
              }, 3000);
            }}
          >
            {isCopied ? (
              <CheckIcon className="h-5 w-5 text-green-400" />
            ) : (
              <ClipboardIcon className="h-5 w-5 text-white" />
            )}
          </button>
        </div>
      ) : isJoinMeetingClicked ? (
        <>
          <input
            defaultValue={studioCode}
            onChange={(e) => {
              setStudioCode(e.target.value);
            }}
            placeholder={"Enter studio code"}
            className="hidden px-4 py-3 bg-gray-650 rounded-xl text-white w-full text-center"
          />
          {studioCodeError && (
            <p className="text-xs text-red-600">
              Please enter valid studioCode
            </p>
          )}
        </>
      ) : null}

      {(iscreateMeetingClicked || isJoinMeetingClicked) && (
        <>
          <input
            value={participantName}
            onChange={(e) => setParticipantName(e.target.value)}
            placeholder="Enter your name"
            className="px-4 py-3 mt-5 bg-[#02061B] rounded-xl text-white w-full text-center"
          />
          <button
            disabled={participantName.length < 3}
            className={`w-full ${participantName.length < 3 ? "bg-gray-650 text-white" : "bg-[#ff9814] text-black"
              }   px-2 py-3 rounded-xl mt-5`}
            onClick={(e) => {
              if (iscreateMeetingClicked) {
                if (videoTrack) {
                  videoTrack.stop();
                  setVideoTrack(null);
                }
                onClickStartMeeting();
                handleMeetingCreated(studioCode)
              } else {
                if (studioCode.match("\\w{4}\\-\\w{4}\\-\\w{4}")) {
                  onClickJoin(studioCode);
                } else setStudioCodeError(true);
              }
            }}
          >
            {iscreateMeetingClicked
              ? isReJoining ? "Re Join Streaming Room" : "Start Live Stream"
              : isJoinMeetingClicked &&
                meetingMode === Constants.modes.CONFERENCE
                ? "Join Studio"
                : isReJoining ? "Re Join Streaming Room" : "Join Streaming Room"}
          </button>
        </>
      )}

      {!iscreateMeetingClicked && !isJoinMeetingClicked && (
        <div className="w-full md:mt-0 mt-4 flex flex-col">
          <div className="flex items-center justify-center flex-col w-full">
            {isCreator && <button
              className="w-full bg-[#ff9814] text-[#02061B] px-2 py-3 rounded-xl disabled:cursor-not-allowed disabled:opacity-[0.5]"
              disabled={isDisabled}
              onClick={async (e) => {
                const studioCode = await _handleOnCreateMeeting();
                setStudioCode(studioCode);
                setIscreateMeetingClicked(true);
                setMeetingMode(Constants.modes.CONFERENCE);
                if (user?.userData?.creator?.username) {
                  setParticipantName(user?.userData?.creator?.username)
                }
                if (studioCode) {
                  localStorage.setItem('studioCode', `https://live.tiwiflix.pro/?code=${studioCode}`)
                }
              }}
            >
              {isReJoining ? "Re Join Stream" : "Start Live Stream"}
            </button>}

            {isCreator ?
              //  <button
              //   className="w-full bg-purple-350 text-white px-2 py-3 mt-5 rounded-xl"
              //   onClick={async (e) => {
              //     setIsJoinMeetingClicked(true);
              //     setMeetingMode(Constants.modes.CONFERENCE);
              //   }}
              // >
              //   Join as a Creator
              // </button>
              <></>
              :
              <>{isViewer && <button
                className="w-full bg-gray-650 text-white px-2 py-3 rounded-xl mt-5"
                onClick={(e) => {
                  setIsJoinMeetingClicked(true);
                  setMeetingMode(Constants.modes.VIEWER);
                }}
              >
                Join Live Stream
              </button>}</>
            }

          </div>
        </div>
      )}
    </div>
  );
}
